<template>
    <div class="">
        <div>
            <!--MainContent-->
            <main>
                <mdb-container class="text-center">
                    <h1>404</h1>
                </mdb-container>
            </main>
            <!--/MainContent-->
        </div>
    </div>
</template>
<script>
import { mdbContainer } from "mdbvue";
export default {
  name: "App",
  components: { mdbContainer },
  data() {
    return {};
  },
};
</script>